<template>
  <div>
    <OrganizationTable title="Alle organisaties" class="mt-4 pt-2 mb-5" />
  </div>
</template>

<script>
import OrganizationTable from "organism/OrganizationTable";

export default {
  components: {
    OrganizationTable,
  },
};
</script>

