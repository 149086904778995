var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-2"},[_c('ProgressSteps',{attrs:{"steps":_vm.steps}}),_c('div',{staticClass:"container step-2__wrapper spacing-bottom"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('a',{staticClass:"btn btn-add",attrs:{"href":"#"},on:{"click":function($event){return _vm.handleAddSample()}}},[_vm._v("Adres toevoegen")]),_c('div',{staticClass:"SampleCardBar"},[(_vm.samples.length !== 0)?_c('div',{},_vm._l((_vm.samples),function(sample,index){return _c('SampleCard',{key:index + '-' + Date.now(),ref:'sample_' + index,refInFor:true,class:{
                active:
                  _vm.selectedSample &&
                  ((sample.id != '' && sample.id == _vm.selectedSample.id) ||
                    (sample.creationstamp &&
                      sample.creationstamp == _vm.selectedSample.creationstamp)),
              },attrs:{"sample":sample,"index":index,"editMode":true},on:{"delete":_vm.handleDeleteSample,"copy":_vm.handleCopySample}})}),1):_vm._e()])]),_c('div',{staticClass:"col-8 px-0"},[(_vm.selectedSample)?_c('FormSteps',{key:_vm.selectedSample.id,ref:"formSteps"}):_c('div',[_vm._v("Nog geen adres geselecteerd.")]),(!_vm.activeReport)?_c('div',{staticClass:"d-flex w-100 h-100 align-items-center justify-content-center mt-5"},[(!_vm.feedback.message)?_c('span',[_vm._v(" Het rapport wordt geladen. We halen het rapport hier opnieuw op om te voorkomen dat er gewerkt wordt met verouderde data. ")]):_vm._e(),_c('Feedback',{attrs:{"feedback":_vm.feedback}})],1):_vm._e()],1)])]),_c('NavigationBar',{attrs:{"prev":_vm.previousStep,"next":_vm.nextStep}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }