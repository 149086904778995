
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TypeTag extends Vue {
  @Prop() private readonly type!: any;

  get typeStyles(): object {
    return {
      color: this.type.color || "white",
      backgroundColor: this.type.bgColor,
    };
  }
}
