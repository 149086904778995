
import { Component, Prop, Vue } from "vue-property-decorator";
import { image } from "../../helpers/assets";

import { getUserId } from "../../services/auth";

@Component
export default class TeamMember extends Vue {
  @Prop() private readonly member!: Object;

  get image() {
    return image;
  }
  get getUserId() {
    return getUserId;
  }
}
